import React from "react";
import { graphql } from "gatsby";

import SEO from "../components/seo";
import { LinkButton, Tag } from "../components/buttons"
import { Box, Spacer, List, Wrapper } from "../components/containers";
import Img from "gatsby-image";
import * as Text from "../components/type";
import { Download } from "react-feather";
import css from "@styled-system/css"
import styled from "styled-components";
import { Link } from "gatsby";
import { getPath } from "../../helpers";

const StyledSection = styled.section`
  width: 100%;

  .media__item {
    aspect-ratio: 16 / 9;
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  .column {
    display: inline-block;
    vertical-align: top;
  }

  .list__subcategories {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    text-transform: uppercase;
  }

  .image-overlay {
    background: rgba(237, 61, 41, 0.3);
    align-items: center;
    justify-content: center;
    opacity: 0;
    color: white;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
  }

  .image-container:hover > .image-overlay {
    opacity: 1;
    transition: 200ms;
  }
`


export default ({data}) => {
  return (
  <Wrapper alignSelf="start">
    <SEO title={data.project.title} />
    <StyledSection>
      <Box className="column column__left" p={[3,3,4]} width={["auto", "auto", "30%"]} position={["static", "static", "sticky"]} top={0}>
        <Box pt={[4, 4, 0]}>
          <Text.Hero fontSize={[3, 4, 4]}>{data.project.title}</Text.Hero>
          <Spacer mb={[3, 3, 4]}/>
          {data.project.description &&
          <Text.RichText json={data.project.description.json} />
          }
          <Spacer mb={[4]} />
          <div>
            <hr />
            <Spacer mb={[4]} />
            <Text.Subheading>Skills</Text.Subheading>
            <Spacer mb={[4]} />
            <ul className="list__subcategories">
            {data.project.subcategories.map((subcategory, idx) =>
              <Tag key={idx} fontFamily="body" fontSize={['.75em']}>
                <Link to={`/${getPath(subcategory.title)}`}>{subcategory.title}</Link>
              </Tag>
            )}
            </ul>
            <Spacer mb={[4]} />
          </div>
          <Spacer mb={[4]} />
          {data.project.attachments &&
          <List flexWrap="wrap">
          {data.project.attachments.map(({title, id, file}) =>
            <li key={id} css={css({marginRight: [0, 1, 2]})}>
              <LinkButton text={title} link={file.url}>
                <Box display="inline" ml={3}>
                  <Download />
                </Box>
              </LinkButton>
            </li>
          )}
          </List>
          }
        </Box>
      </Box>
      <Box className="column" p={[3,3,4]} width={["100%", "100%", "70%"]}>
        <ul>
        {data.project.media.map((media, idx) =>
          <li className="media__item" key={idx}>
            <Box height="100%" mb={[3]} p={[0,0,2]} position="relative">
                {(media['__typename'] === 'ContentfulVideo') ?
                <iframe
                src={media.link}
                frameborder="0"
                allow="accelerometer; autoplay; fullscreen; encrypted-media; gyroscope; picture-in-picture"
                allowtransparency="true"
                allowFullScreen="true"
                title="video player"
                /> :
                <Box className="image-container" height="100%">
                  <Box className="image-overlay" display={["none", "none", "flex"]}>
                    <Box background="black" padding={3}>
                      <Text.Paragraph fontFamily="display" fontSize={3} margin={0}>{media.image.title}</Text.Paragraph>
                    </Box>
                  </Box>
                  <Img
                  style={{ height: "100%", width: "100%" }}
                  imgStyle={{ objectFit: "contain"}}
                  title={media.image.title ?? ""}
                  fluid={media.image.fluid} />
                </Box>
                }
            </Box>
          </li>
        )}
        </ul>
      </Box>
    </StyledSection>
  </Wrapper>
  )
};

export const query = graphql`
query ($slug: String!) {
  project: contentfulProject(id: {eq: $slug}) {
    attachments {
      title
      id
      file {
        url
      }
    }
    description {
      json
    }
    category {
      title
    }
    subcategories {
      title
    }
    title
    preview {
      id
    }
    media {
      ... on ContentfulImage {
        id
        image {
          fluid(quality: 100) {
            ...GatsbyContentfulFluid
          }
          fixed(width: 500, quality: 70) {
            src
          }
          title
          description
        }
        thumbnail {
          title
          fixed(width: 400, quality: 70) {
            src
            srcSet
          }
        }
      }
      ... on ContentfulVideo {
        id
        title
        thumbnail {
          fixed(height: 400, width: 400, quality: 70) {
            src
          }
          title
        }
        thumbnailUrl
        link
      }
    }
  }
}
`;